import React from 'react';
import './App.css';
import { Navigate, useNavigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Box from '@mui/material/Box';
import AppRouter from './hooks/appNavigator';
import { ProductProps } from './types';
import InvictusImage from './assets/Invictus100ml.webp';
import Chanel5Image from './assets/ChanelN5.webp';
import ProductPage from './pages/ProductPage';
import OneMillionImage from './assets/OneMillion100mlHm.webp'
import AquaImage from './assets/armaniacquadigioparfum.webp'
import EuphoriaImage from './assets/ckeuphoria.webp'
import DolceImage from './assets/dolcegabbanalightblue.webp'
import SoloImage from './assets/Solo-Loewe-Cedro.webp'
import LacosteImage from './assets/lacostewhite.jpeg'
import VieImage from './assets/La-vie-est-belle.jpg'
import BombshellImage from './assets/victoriasecret.jpeg'
import EauToiletteImage from './assets/eaudetoilette.jpeg';
import OmbreNomadeImage from './assets/ombrenomade.jpeg';
import YaraImage from './assets/yara.jpeg';
import JadoreImage from './assets/jadore.jpeg';
import RedStyleImage from './assets/lacoste.jpeg';
import Vip212Image from './assets/212vip.jpeg';
import GoodGirlImage from './assets/goodgirlherrera.jpeg';
import SilverMountainImage from './assets/silvermountainwater.jpeg';
import AmberRougeImage from './assets/amberrouge.jpeg';
import BurBerryImage from './assets/burberry.jpeg';
import OlympeaImage from './assets/olympea.avif'
import { Text } from './modules/text';
import TextButton from './modules/textButton';
import { useMediaQuery } from '@mui/material';

const data_raw: ProductProps[] = [
  { name: 'INVICTUS 100mL', description: 'Una fragancia fresca, amaderada y heroica, en donde la vibración toma forma de músculo. En Invictus se da cuerpo a dos fuerzas que colisionan: la frescura penetrante y la sensualidad animal. La brisa marina domina y el pomelo estalla. El aroma a madera domina encendido, junto al perfume del magnetismo del pachuli, la sensualidad del guayaco y del ámbar gris. La victoria es doble.', gender: 'hombre', aroma: 'amaderado', price: '200000', discount: 20, amount: 1000, image: [InvictusImage], sellAmount: 0, addedDate: Date.now.toString(), category: 'normal' },
  { name: 'CHANEL N°5 100mL', description: 'N°5, la esencia misma de la feminidad. Un bouquet floral aldehído sublimado por un frasco emblemático con líneas minimalistas. Un perfume icónico y atemporal.', gender: 'mujer', aroma: 'floral/frutal', price: '270000', discount: 20, amount: 1000, image: [Chanel5Image], sellAmount: 0, addedDate: Date.now.toString(), category: 'normal' },
  { name: 'ONE MILLION 100mL', description: 'Notas frescas y picantes de cuero auténtico. Su aroma se hace notar desde el principio gracias a la fuerza de la menta y al dulzor de la mandarina. La canela pone su toque de intriga también. ¿El resultado? Un perfume distinguido y original que representa el espíritu del hombre y la pasión. Una fragancia refrescante y que llama la atención con sus toques de madera blanca, ámbar, cuero y pachulí.', gender: 'hombre', aroma: 'dulce', price: '200000', discount: 20, amount: 1000, image: [OneMillionImage], sellAmount: 0, addedDate: Date.now.toString(), category: 'normal' },
  { name: 'ACQUA DI GIO 125mL', description: 'Una esencia aromática amaderada que combina bergamota con esencia de romero y notas de incienso para crear una intensidad vibrante y carismática. Formulado con ingredientes de fuentes sostenibles como corazón de bergamota, corazón de salvia y esencia de pachulí.', gender: 'hombre', aroma: 'amaderado', price: '260000', discount: 15, amount: 1000, image: [AquaImage], sellAmount: 0, addedDate: Date.now.toString(), category: 'normal' },
  { name: 'CALVIN KLEIN EUPHORIA 100mL', description: 'Se inspira en la sencilla elegancia de la orquídea. Esta fragancia femenina se abre con un suculento caqui y una adictiva granada. Una mezcla embriagadora de flor de loto y orquídea te envuelven aún más, con capas de ámbar líquido y madera de caoba exuberante.', gender: 'hombre', aroma: 'floral/frutal', price: '210000', discount: 5, amount: 1000, image: [EuphoriaImage], sellAmount: 0, addedDate: Date.now.toString(), category: 'normal' },
  { name: 'DOLCE AND GABBANA LIGHT BLUE 100mL', description: 'El perfume de mujer Dolce&Gabbana Light Blue contiene la expresión del carácter femenino mediterráneo, espontáneo y desenfadado. En su fragancia, la frescura de los cítricos acompañada de un suave bouquet floral da paso a unas cálidas y seductoras notas amaderadas.', gender: 'mujer', aroma: 'frescos/citricos', price: '220000', discount: 20, amount: 1000, image: [DolceImage], sellAmount: 0, addedDate: Date.now.toString(), category: 'normal' },
  { name: 'SOLO LOEWE 100mL', description: 'Una fragancia atemporal y novedosa, cuyo secreto radica en los acordes contrapuestos y que antepone las notas aromáticas dos a dos, perfectamente equilibrados entre sí. Una fragancia especiada y amaderada, con el sándalo, la guaba y el mazapán entre sus notas contrapuestas para el hombre único y elegante que busca el equilibrio. La armonía de los extremos.', gender: 'hombre', aroma: 'amaderado', price: '250000', discount: 20, amount: 1000, image: [SoloImage], sellAmount: 0, addedDate: Date.now.toString(), category: 'normal' },
  { name: 'LACOSTE WHITE 100mL', description: 'Una salida fresca y especiada, con notas de toronja jugosa y cardamomo especiado que luego se mezclan con vetiver y gamuza suave, todo culminando en una sensación de elegancia fresca.', gender: 'hombre', aroma: 'frescos/citricos', price: '200000', discount: 20, amount: 1000, image: [LacosteImage], sellAmount: 0, addedDate: Date.now.toString(), category: 'normal' },
  { name: 'LA VIE EST BELLE 100mL', description: 'Una salida fresca y especiada, con notas de toronja jugosa y cardamomo especiado que luego se mezclan con vetiver y gamuza suave, todo culminando en una sensación de elegancia fresca.', gender: 'mujer', aroma: 'frescos/citricos', price: '200000', discount: 20, amount: 1000, image: [VieImage], sellAmount: 0, addedDate: Date.now.toString(), category: 'normal' },
  { name: 'VICTORIA SECRET BOMBSHELL 100mL', description: 'Bombshell de Victoria´s Secret es una fragancia de la familia olfativa Floral Frutal para Mujeres. Bombshell se lanzó en 2010. Bombshell fue creada por Adriana Medina-Baez y Mark Knitowski. Las Notas de Salida son maracuyá (fruta de la pasión), toronja (pomelo), piña, naranja tangerina y fresa; las Notas de Corazón son peonía, orquídea de vainilla, bayas rojas, jazmín y lirio de los valles (muguete); las Notas de Fondo son almizcle, notas amaderadas y musgo de roble.', gender: 'mujer', aroma: 'floral/frutal', price: '180000', discount: 15, amount: 1000, image: [BombshellImage], sellAmount: 0, addedDate: Date.now.toString(), category: 'normal' },
  { name: 'EAU DE CARTIER - EAU DE TOILETTE 100mL', description: 'Las Notas de Salida son yuzu, bergamota y cilantro; las Notas de Corazón son hojas de violeta, violeta y lavanda; las Notas de Fondo son cedro, almizcle, ámbar blanco y pachulí.', gender: 'unisex', aroma: 'floral/frutal', price: '250000', discount: 20, amount: 1000, image: [EauToiletteImage], sellAmount: 0, addedDate: Date.now.toString(), category: 'normal' },
  { name: 'OMBRE NOMADE LOUIS VUITTON 100mL', description: 'A medida que transcurre el día, el camino del sol crea patrones brillantes de sombras y luces en las dunas. Mientras todo lo demás parece inmóvil, el desierto cobra vida y atrae al viajero hacia una apasionante odisea. Diseñado para los amantes de las esencias raras, Ombre Nomade concentra esa sensación de infinito en uno de los ingredientes más míticos de la perfumería, la madera de oud.', gender: 'hombre', aroma: 'amaderado', price: '300000', discount: 15, amount: 1000, image: [OmbreNomadeImage], sellAmount: 0, addedDate: Date.now.toString(), category: 'normal' },
  { name: 'YARA DE LATTAFA 100mL', description: 'Yara de Lattafa Perfumes es una fragancia de la familia Amber Vanilla para mujeres. Yara se lanzó en el año 2020. Las Notas de Salida de Yara son orquídea, heliotropo y mandarina; las Notas de Corazón son Acorde Gourmand y Frutas Tropicales; las Notas de Fondo son vainilla, almizcle y sándalo.', gender: 'mujer', aroma: 'floral/frutal', price: '200000', discount: 45, amount: 1000, image: [YaraImage], sellAmount: 0, addedDate: Date.now.toString(), category: 'normal' },
  { name: "J'ADORE DIOR 100mL", description: "Tan legendario como delicado, el jazmín revela toda la riqueza plural de J'adore, sus matices únicos, sus facetas afrutadas, su luminosa redondez floral. Único, intenso como ningún otro, el oro blanco de Grasse es una flor excepcional que brilla en el corazón de J'adore.", gender: 'mujer', aroma: 'floral/frutal', price: '250000', discount: 20, amount: 1000, image: [JadoreImage], sellAmount: 0, addedDate: Date.now.toString(), category: 'normal' },
  { name: 'RED STYLE IN PLAY LACOSTE EDT 125mL', description: "Perfume Red Style in Play de Lacoste EDT 125 ML Perfume Red Style in Play de Lacoste Fragrances es una fragancia de la familia olfativa Almizcle Amaderado Floral para Hombres.", gender: 'hombre', aroma: 'amaderado', price: '200000', discount: 20, amount: 1000, image: [RedStyleImage], sellAmount: 0, addedDate: Date.now.toString(), category: 'normal' },
  { name: '212 VIP ROSÉ 80mL', description: "Un símbolo continuo de diversión y feminidad, 212 VIP ROSE lleva el espíritu animado de una icónica ciudad de Nueva York al ritmo de cada día y ritmo de cada noche, dentro de su botella de firma VIP rosa mate.", gender: 'mujer', aroma: 'floral/frutal', price: '250000', discount: 20, amount: 1000, image: [Vip212Image], sellAmount: 0, addedDate: Date.now.toString(), category: 'normal' },
  { name: 'GOOD GIRL CAROLINA HERRERA 50mL', description: "Las cualidades dulces y seductoras del jazmín le aportan una brillante feminidad a Good Girl. El rico y fragante cacao y la embriagadora haba tonka expresan el lado misterioso de Good Girl, mientras que la almendra y el café le aportan notas de atrevida vitalidad.", gender: 'mujer', aroma: 'dulce', price: '200000', discount: 20, amount: 1000, image: [GoodGirlImage], sellAmount: 0, addedDate: Date.now.toString(), category: 'normal' },
  { name: 'SILVER MOUNTAIN WATER CREED 100mL', description: "El aventurero Silver Mountain Water evoca arroyos brillantes que atraviesan los Alpes suizos cubiertos de nieve, un paisaje vigorizante en el que Olivier Creed, un esquiador de campeonato, encuentra relajación y renovación. Un éxito de ventas desde su lanzamiento, este aroma moderno captura la pureza de las montañas: grosellas negras suaves, lechosas y dulces mezcladas con té verde, la riqueza de la bergamota y el sándalo. Su botella blanca opaca insinúa bancos de nieve helada coronados con una tapa brillante como un arroyo plateado glacial.", gender: 'hombre', aroma: 'dulce', price: '300000', discount: 20, amount: 1000, image: [SilverMountainImage], sellAmount: 0, addedDate: Date.now.toString(), category: 'normal' },
  { name: 'AMBER ROUGE ORIENTICA 80mL', description: "Como un velo de novia rojo adornado con bordados dorados exuberantes con el aroma de la celebración, Amber Rouge llama a quienes buscan plenitud... aquellos que quieren tomar el amor en el aire y convertirlo en vientos de pasión y obsesión. Las cautivadoras fragancias de jazmín y naranja encienden la primera chispa, y con azafrán y praliné en su corazón palpitante, musgo de roble, ámbar y algas marinas son los aromas distintivos que permanecen contigo mucho después de que los momentos tempestuosos te hayan dejado sin aliento por más.", gender: 'unisex', aroma: 'floral/frutal', price: '210000', discount: 19, amount: 1000, image: [AmberRougeImage], sellAmount: 0, addedDate: Date.now.toString(), category: 'normal' },
  { name: 'BURBERRY 50mL', description: "Su Eau de Parfum, el primer Gourmand con un toque británico de Burberry. Notas de bayas rojas y oscuras iluminadas por un luminoso acorde de madera blanca. Es una dedicatoria a Ella, la bulliciosa ciudad de Londres. Vibrante, audaz, lleno de vida.", gender: 'mujer', aroma: 'floral/frutal', price: '250000', discount: 20, amount: 1000, image: [BurBerryImage], sellAmount: 0, addedDate: Date.now.toString(), category: 'normal' },
  { name: 'OLYMPEA PACO RABANNE 50 mL EDP', description: "Un aroma oriental único con vainilla salada que provoca una armonia en la piel. Y un duelo entre lo fresco y carnal: impulso floral, mandarina verde, agua de jazmín y flor de jengibre. La vainilla salada deja su rastro en la piel y genera una fragancia casi animal y muy femenina. El ámbar gris y la madera de Cachemira la potencian. Como una joya, la tapa decorada con una piedra preciosa se retira para revelar una fragancia de una estela divina.", gender: 'mujer', aroma: 'floral/frutal', price: '250000', discount: 20, amount: 1000, image: [OlympeaImage], sellAmount: 0, addedDate: Date.now.toString(), category: 'normal' },
]
function App() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  const routes = [
    { path: "/", element: <Navigate to="/home" /> },
    { path: "/home", element: <HomePage items={data_raw} /> },
    { path: '/product/:product', element: <ProductPage product={data_raw[0]} /> },
  ];

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Box
        position='relative'
        display='flex'
        justifyContent='flex-start'
        alignItems='flex-start'
        flexDirection='column'
        height={isMobile ? '100px' : '150px'}
        width='100%'
        sx={{ backgroundColor: "rgb(234, 190, 63)" }}
      >
        <TextButton
          text='D´Fraganto'
          textWeigth='500'
          fontSize={isMobile ? 50 : 70}
          fontFamily='Great Vibes'
          variant='plain'
          isActive={false}
          underline={false}
          paddingLeft={isMobile ? '20px' : '80px'}
          onClick={() => { navigate('/home') }}
        />
        <Text
          text='Un mundo de sensaciones'
          fontWeight='500'
          size={isMobile ? 30 : 35}
          fontFamily='Great Vibes'
          position='absolute'
          marginTop={isMobile ? '50px' : '70px'}
          marginLeft={isMobile ? '30px' : '120px'}
        />
      </Box>
      <Box height='10px' />
      <Box flexGrow={1}>
        <AppRouter routes={routes} />
      </Box>
    </Box>
  );
}

export default App;

