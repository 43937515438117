import * as React from 'react';


export const useFormatPrice = (price: string) => {
    const number = parseFloat(price.replace(/,/g, ''));
    return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(number);
};

export const useCalculateDiscountedPrice = (price: string, discountPercentage: number) => {
    const number = parseFloat(price.replace(/,/g, ''));
    const discountedPrice = number - (number * discountPercentage) / 100;
    return useFormatPrice(discountedPrice.toString());
};

