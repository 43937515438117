import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Box from "@mui/material/Box";
import { Text } from "../modules/text";
import SwippingImageCard from "../modules/productPage/swippingImageCard";
import { useCalculateDiscountedPrice, useFormatPrice } from "../hooks/product/formatPrice";
import Typography from '@mui/joy/Typography';
import Divider from '@mui/material/Divider';
import { ProductPageProps, ProductProps } from '../types';
import { capitalizeFirstLetter } from '../utils';
import { useMediaQuery } from '@mui/material';
import TextButton from '../modules/textButton';
import { NUMBER } from '../constants';

const ProductPage: React.FC<ProductPageProps> = () => {
    const location = useLocation();
    const product = location.state.product as ProductProps;
    const isMobile = useMediaQuery('(max-width:600px)');

    const formattedPrice = useFormatPrice(product.price);
    const calculatedDiscountPrice = useCalculateDiscountedPrice(product.price, product.discount);
    const discountedPrice = product.discount ? calculatedDiscountPrice : null;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const images: string[] = product.image;

    const message_1: string = 'Buenas tardes, estoy interesad@ en el producto ' + product.name + '. El precio sugerido en la página es de: ' + product.price + '. Muchas gracias.'
    const message_2: string = 'Buenas tardes, estoy interesad@ en el producto ' + product.name + '. El precio sugerido en la página es de: ' + formattedPrice + '. Con el descuento en la página del ' + product.discount + '% el precio final queda en: ' + discountedPrice  +'. Muchas gracias.'
    const message: string = discountedPrice?message_2:message_1;

    const handleButtonClick = () => {
        const url = `https://wa.me/${NUMBER}?text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
    };

    return (
        <Box>
            <div className="espacio" id="space_div"></div>
            <Box
                position='relative'
                width='100%'
                height='100%'
                display='flex'
                flexDirection={isMobile ? 'column' : 'row'}
            >
                <Box
                    width='100%'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                >
                    <SwippingImageCard images={images} isMobile={isMobile} />
                </Box>
                <Box
                    width={isMobile ? '90%' : '50%'}
                    display='flex'
                    flexDirection='column'
                    alignItems='flex-start'
                    justifyContent='flex-start'
                    gap='15px'
                    maxHeight={isMobile ? 'none' : '100vh'}
                    sx={{ overflowY: isMobile ? 'visible' : 'auto', padding: isMobile ? '20px' : '0' }}
                >
                    <Box width='90%' gap='10px'>
                        <Text text={product.name} fontWeight="bold" size={isMobile ? 24 : 32} padding="0px" />
                    </Box>
                    <div>
                        <Typography level="body-xs">{!discountedPrice ? 'Precio:' : 'Precio original:'}</Typography>
                        <Typography
                            fontSize={isMobile ? "md" : "lg"}
                            alignContent='flex-start'
                            fontWeight="lg"
                            sx={{ textDecoration: discountedPrice ? 'line-through' : 'none', color: discountedPrice ? 'red' : 'inherit' }}
                        >
                            {formattedPrice} COP
                        </Typography>
                    </div>
                    <div>
                        {discountedPrice && (
                            <>
                                <Typography level="body-xs">
                                    Precio {discountedPrice && ` con ${product.discount}% de descuento`}:
                                </Typography>
                                <Typography fontSize={isMobile ? "md" : "lg"} fontWeight="lg">
                                    {discountedPrice} COP
                                </Typography>
                            </>
                        )}
                    </div>
                    <Box width='100%' display='flex' justifyContent={isMobile?'center':'flex-start'} alignItems='center'>
                        <TextButton text={'Comprar'} width={isMobile ? '90%' : '35%'} paddingLeft='20px' variant={'outlined'} fontSize={20} isActive={false} underline={false} onClick={()=>{handleButtonClick();}}></TextButton>
                    </Box>

                    <Divider sx={{ width: isMobile?'100%':  '90%' }}></Divider>
                    <Box display='flex' flexDirection='column' sx={{ width: '90%' }} gap={2}>
                        {product.description.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                <Text text={line} fontWeight="200" size={isMobile ? 14 : 16} padding="0px"></Text>
                            </React.Fragment>
                        ))}
                        <Divider sx={{width: isMobile?'100%':  '90%' }}></Divider>
                        <Box>
                            <Box display='flex' gap={1}>
                                <Text text='Fragancia para: ' fontWeight="bold" size={isMobile ? 14 : 16} padding="0px"></Text>
                                <Text text={capitalizeFirstLetter(product.gender)} fontWeight="500" size={isMobile ? 14 : 16} padding="0px"></Text>
                            </Box>
                            <Box display='flex' gap={1}>
                                <Text text='Aroma: ' fontWeight="bold" size={isMobile ? 14 : 16} padding="0px"></Text>
                                <Text text={capitalizeFirstLetter(product.aroma)} fontWeight="500" size={isMobile ? 14 : 16} padding="0px"></Text>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <div className="espacio" id="space_div"></div>
        </Box>
    );
};

export default ProductPage;