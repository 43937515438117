import * as React from "react"
import Box from "@mui/material/Box";
import { ImageButton } from "../imageButton";
import { SwippingImageCardProps } from "../../types";

const SwippingImageCard: React.FC<SwippingImageCardProps> = ({images, isMobile}) => {
    const [selectedImage, setSelectedImage] = React.useState(images[0]);
    return (
        <Box display="flex" height={isMobile?"350px":"500px"} width={isMobile?"350px":"auto"} alignItems="center" justifyContent='center'>
            <Box
                position='static'
                left={0}
                width={isMobile?"40px":"80px"}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="flex-start"
                overflow="auto"
                height="auto"
                padding={isMobile?"0px":"10px"}
                zIndex={1}
            >
                {images.map((image, index) => (
                    <ImageButton
                        key={index}
                        image={image}
                        onClick={() => setSelectedImage(image)}
                        paddingTop="100%"
                    />
                ))}
            </Box>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="auto"
                maxWidth="400px"
            >
                <img src={selectedImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </Box>
        </Box>
    );
};

export default SwippingImageCard;