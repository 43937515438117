import Box from '@mui/material/Box';
import * as React from 'react';
import { GridCustom } from '../modules/grid';
import { GridCustomProps } from '../types';
import WhatsAppButton from '../modules/whatsAppButton';
import { useMediaQuery } from '@mui/material';
import { Text } from '../modules/text';

const HomePage: React.FC<GridCustomProps> = ({ items }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <>
            <Box width='100%' height='100%' display='flex' justifyContent='center' paddingBottom='20px'>
                <Box width='5%'>
                </Box>
                <Box width={isMobile ? '70%' : '80%'}>
                    <GridCustom items={items} isMobile={isMobile}></GridCustom>
                </Box>
                <Box width='5%' position='relative' height='100vh'>
                    <WhatsAppButton message='Buenas tardes. ¿Qué productos están disponibles?' isMobile={isMobile}></WhatsAppButton>
                </Box>
            </Box>
            <Box width='100%' height={isMobile?'100px':'200px'} display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: "rgb(234, 190, 63)" }}>
                <Text text={"Pregunta por más referencias en nuestro What'sApp!"} fontWeight={'500'} size={isMobile?22:40} padding='10px'></Text>
            </Box>

        </>
    );
};

export default HomePage;