import React from 'react';
import Button from '@mui/joy/Button';
import { TextButtonProps } from '../types';


const TextButton: React.FC<TextButtonProps> = ({ text, variant, fontSize, isActive, onClick, underline, textAlign = 'center', textWeigth = 'bold',fontFamily='Poppins, sans-serif', paddingLeft='0px', width='auto'}) => {
    return (
        <Button
            color='warning'
            onClick={() => {
                onClick();
            }}
            variant={variant}
            sx={{
                position: 'static',
                fontSize: fontSize,
                fontFamily: fontFamily,
                fontWeight: textWeigth,
                color: 'black',
                textDecoration: isActive ? 'underline' : 'none',
                textAlign: textAlign,
                paddingLeft: paddingLeft,
                width:width,
                '&:hover': {
                    borderColor: variant === 'outlined' ? 'rgb(255, 190, 63)' : 'transparent',
                    backgroundColor: variant === 'outlined' ? 'rgba(234, 190, 63, 0.1)' : 'inherit',
                    textDecoration: underline ? 'underline' : 'none',
                }
            }}
        >
            {text}
        </Button>
    );

};

export default TextButton;