import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { GridCustomProps } from '../types';
import { ItemButton } from './itemButton';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import { useEffect, useRef, useState } from 'react';

export const GridCustom: React.FC<GridCustomProps> = ({ items, isMobile }) => {
    const [page, setPage] = useState(1);
    const itemsPerPage = 21;
    const count = Math.ceil(items.length / itemsPerPage);
    const gridRef = useRef<HTMLDivElement>(null);


    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        if (gridRef.current) {
            gridRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        setPage(1);
    }, [items]);

    const startIndex = (page - 1) * itemsPerPage;
    const selectedItems = items.slice(startIndex, startIndex + itemsPerPage);


    return (
        <>
            <div ref={gridRef}>
                <Grid container spacing={5} display="flex" width='auto' justifyContent='center' alignContent='center' border="hidden" margin={0}>
                    {selectedItems.map((item, index) => (
                        <ItemButton key={index} product={item} isMobile={isMobile}></ItemButton>
                    ))}
                </Grid>
            </div>
            <div className='espacio'></div>
            <Stack spacing={2} alignItems="center" mt={2}>
                <Pagination count={count} page={page} onChange={handleChange} />
            </Stack>
        </>
    );

}