import React from 'react';
import { IconButton, styled } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { WhatsAppButtonProps } from '../types';
import { NUMBER } from '../constants';


const CircularButton = styled(IconButton)(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: '#25D366',
  position: 'fixed',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#128C7E',
  },
}));

const WhatsAppButton: React.FC<WhatsAppButtonProps> = ({ phoneNumber= '+573164052781', message, isMobile }) => {
  const handleButtonClick = () => {
    const url = `https://wa.me/${NUMBER}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <CircularButton 
    onClick={handleButtonClick} aria-label="Envíanos un mensaje!"
    sx={{
      width:isMobile?'50px':'90px',
      height:isMobile?'50px':'90px',
      marginTop:isMobile?'570px':'500px',
    }}
    >
      <WhatsAppIcon fontSize='large'/> 
    </CircularButton>
  );
};

export default WhatsAppButton;
