export const normalizeString = (str: string): string => {
    return str
      .toLowerCase()
      .replace(/\//g, '-')
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "") // This removes all diacritical marks
      .replace(/[^\w\s-]/g, "") // This removes any remaining non-word characters except spaces and hyphens
      .trim()
      .replace(/\s+/g, '-'); // This replaces spaces with hyphens
  };

export function capitalizeFirstLetter(string: string): string {
    if (string.length === 0) return string;
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}