import * as React from 'react';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import { ImageButtonProps } from '../types';


const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    overflow: 'hidden'
}));

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
});

const ImageMarked = styled('span')(({ theme }) => ({
    height: 0,
    width: 0,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: 0,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
}));

export const ImageButton: React.FC<ImageButtonProps> = ({ image, title, paddingTop='26.04%', onClick }) => {
    return (
        <StyledButtonBase onClick={onClick} sx={{paddingTop: paddingTop}}>
            <ImageSrc style={{ backgroundImage: `url(${image})` }} />
            {title && (
                <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                        padding: '5px',
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 'bold',
                        fontFamily: 'Poppins, sans-serif',
                        objectFit:'cover'
                    }}
                >
                    {title}
                    <ImageMarked className="MuiImageMarked-root" />
                </Typography>
            )}
        </StyledButtonBase>
    );
};
