import React from 'react';
import Typography from '@mui/material/Typography';
import { TextProps } from '../types';


export const Text: React.FC<TextProps> = ({ position='static', text, fontWeight, size, padding='80px', paddingTop='0px', fontFamily= 'Poppins, sans-serif', marginTop='0px', marginLeft='0px'}) => {
    return (
            <Typography component="span"
                variant="subtitle1"
                color="inherit"
                sx={{
                    paddingTop: paddingTop,
                    position:position,
                    display: 'flex',
                    width: 'auto',
                    paddingLeft:padding,
                    color: 'black',
                    fontSize: size,
                    fontWeight: fontWeight,
                    fontFamily: fontFamily,
                    marginLeft: marginLeft,
                    marginTop: marginTop,
                }}>
                {text}
            </Typography>
    );
}
