import React from 'react';
import Card from '@mui/joy/Card';
import { ImageButton } from './imageButton';
import TextButton from './textButton';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import { ItemButtonProps } from '../types';
import { useFormatPrice, useCalculateDiscountedPrice } from '../hooks/product/formatPrice';
import { useNavigate } from 'react-router';
import { normalizeString } from '../utils';


export const ItemButton: React.FC<ItemButtonProps> = ({ product, isMobile }) => {

    const formattedPrice = useFormatPrice(product.price);
    const calculatedDiscountPrice = useCalculateDiscountedPrice(product.price, product.discount)
    const discountedPrice = product.discount ? calculatedDiscountPrice : null;

    const navigate = useNavigate();

    const handleProductClick = () => {
        navigate(`/product/${normalizeString(product.name!)}`, { state: { product } });
    };

    console.log('Product Image:', product.image);

    return (
        <Card sx={{ width: 300, height: 400, border: 'hidden', backgroundColor: 'transparent' }}>
            <div style={{ position: 'relative', height: '100%' }}>
                <ImageButton image={product.image[0]} onClick={() => {handleProductClick()}}/>
            </div>
            <TextButton text={product.name} variant='plain' textWeigth='500' fontSize={16} underline={false} isActive={false} textAlign='left' onClick={() => { }}></TextButton>
            <CardContent orientation="horizontal">
                <div>
                    <Typography level="body-xs">{!discountedPrice ? 'Precio:' : 'Precio original:'}</Typography>
                    <Typography fontSize="sm" fontWeight="lg" sx={{ textDecoration: discountedPrice ? 'line-through' : 'none', color: discountedPrice ? 'red' : 'inherit' }}>
                        {formattedPrice} COP
                    </Typography>
                </div>
                <div>
                    {discountedPrice && (
                        <Typography level="body-xs"> Precio {discountedPrice && (' con ' + product.discount + ' % de descuento')}: </Typography>
                    )}
                    {discountedPrice && (
                        <Typography fontSize="sm" fontWeight="lg">
                            {discountedPrice} COP
                        </Typography>
                    )}
                </div>
            </CardContent>
        </Card>
    );
}